.border{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.popUpModalContent {
    width: fit-content;
    border-radius: .75rem;
    padding: 0 20px;
}

.popUpBody {
    width: fit-content !important;
}

.popUpContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: white;
    height: fit-content;
    width: 100%;
    padding: 20px;

    border-radius: 30px;
    z-index: 90000;
}

.popUpContainer > p {
    margin-bottom: 30px;
    font-family: 'Work Sans', 'Regular roman';
    font-size: 20px;
    text-align: center;
}

.popUpContainer div img {
    width: 80px;
    margin-bottom: 20px;
}

.popUpContainer div button{
    width: 90px;
    height: 35px;
    border-radius: 20px;

    background: #FFD500;
    border: #FFD500;
}

.input {
    background-color: #343434;
    border-radius: 50;
    color: #fff;
    text-transform: capitalize;
    font-family: 'Work Sans';
}

.MuiInputBase-input {
    color: red;
}

.input:disabled {
    color: white;
}

.modal-open .modal {
    z-index: 9999999;
}

/* import erro from '../assets/icons/Ativo8.png';
import styles from '../styles/PopUp.module.css';
import sucess from '../assets/icons/Ativo7.png'; */

@media (max-width: 858px) {

    .popUpModalContent {
        width: 80%;
        padding: 20px 0;
    }
    .popUpContainer {
        width: 100%;
    }

}