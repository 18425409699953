@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}

html,
body,
#root {
  height: 100vh;
  background-color: #141414;
}

::-webkit-scrollbar-track {
  background-color: #212529;
}

::-webkit-scrollbar {
  width: 6px;
  background: #212529;
}

::-webkit-scrollbar-thumb {
  background: #dad7d7;
  border-radius: 25px;
}

code {
  font-family: "Work Sans", sans-serif;
}
