.container-verified {
  text-align: center;
  padding: 40px 0;
  background-color: #141414;
}
.h1-verified {
  color: #ffd500;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 10px;
}
.para-verified {
  color: #ffffff;
  font-size: 20px;
  margin: 0;
}

.card {
  width: 500px;
  background: #fff;
  padding: 42px 55px 45px;
  display: inline-block;
  margin: 0 auto;
  color: #ffffff;
  background-color: #141414;
}

.img-verified {
  width: 100px;
  margin: 30px;
}

.link-button {
  height: 60px;
  background-color: #ffd500;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: #c8d0d8;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  padding: 20px;
  text-decoration: none;
  transition: filter 0.2s;
  cursor: pointer;
}

.link-button:hover {
  filter: brightness(90%);
  text-decoration: none;
  color: #ffffff;
}

.link-verified {
  margin-top: 80px;
}

@media (max-width: 858px) {
  .card {
    width: 100%;
    padding: 0;
  }
}
