.Input {
  align-self: center;
}
.Email {
  border-radius: 20px;
  margin-top: 5%;
  width: 300px;
}
.Password {
  border-radius: 20px;
}
.Register {
  border-radius: 20px;
}
.BottomCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ForgotPassword {
  font-size: 1rem;
  margin-top: 5px;
  display: flex;
  align-self: flex-end;
  color: #ffd500;
  font-family: "Work Sans", sans-serif;
  border: none;
  background-color: transparent;
}
.ForgotPassword:hover {
  text-decoration: underline;
}
.KeepConnected {
  display: flex;
  justify-content: space-between;
}
.BtbLogin {
  margin-top: 20px;
  border-radius: 20px;
  background-color: #ffd500;
  color: #252525;
  width: 100%;
  font-family: "Work Sans", sans-serif;
}
.BtbRegister {
  border-radius: 20px;
  margin-top: 10px;
  background-color: #252525;
  font-family: "Work Sans", sans-serif;
}
.errorMessage {
  color: red;
  font-size: 12px;
  padding: 6px 12px;
}
