.profile-container {
    min-height: fit-content;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 858px) {
    .right-container-profile {
        /* width: 100vw; */
        display: flex;
    }

    .historico-grid-xs6 {
        width: 100% !important;
        max-width: 100% !important;
    }

    .historico-grid-xs6+.historico-grid-xs6 {
        margin-top: 24px !important;
    }

    .divider {
        display: none;
    }

    .voltar-btn-container {
        width: 50%;
        margin: 0 auto;
        margin-top: 32px;
    }

    .profile-container {
        height: auto;
    }

    .more-detail-dialog .MuiDialog-paper {
        overflow-x: hidden !important;
        max-height: none;
        margin: 16px;
    }
}