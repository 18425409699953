
.ver-ingressos-btn{
    text-transform: capitalize;
    font-family: 'Work Sans', sans-serif;
    border-radius: 50%;
    width: 100%;
}

.finalizar-btns-container {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.appleStoreLink {
    margin-left: -15px;
}

.storeImgsContainer {
    height: 80px;
    margin-top: 10px;
}

@media (max-width: 858px) {
    .left-container-responsive {
        padding: 0;
    }

    .storeImgsContainer {
        flex-direction: column;
        height: fit-content;
        margin: 0;
    }

    .finalizar-btns-container {
        flex-direction: column;
        height: 80px;
        margin: 0;
    }

    .appleStoreLink {
        margin-left: 0;
    }
    
    .finalizar-btns-container div+ div {
        margin-top: 10px;
    }

    .ver-ingressos-btn {
        padding: 0 !important;
        padding-bottom: 32px !important;
    }
}