.root {
    width: 100%;
    padding: 0 16px;
}

.root-responsive {
    display: none;
}

.boxes-container {
    display: flex;
}

.main-box {
    width: 70%;
}


@media (max-width: 858px) {
    .root {
        display: none;
    }

    .root-responsive {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
    }

    .responsive-grid {
        padding: 0 8px;
        width: 100%;
        max-width: 100% !important;
    }

    .responsive-grid .g-card-cont+.g-card-cont {
        margin-top: 24px;
    }
    
    .g-card-cont {
        padding: 0 !important;
        width: 100%;
        min-width: 100%;
    }

    .grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .boxes-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-box {
        width: 100%;
    }

    .main-box .MuiTypography-h5 {
        font-size: 1rem;
    }

    .MuiGrid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}