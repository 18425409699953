.container {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;

  color: #fff;
}

.container__header {
  text-align: center;
}

.container__header p {
  margin-top: 20px !important;
  font-size: 15px !important;
  text-decoration: underline !important;
  margin: 0;
  color: #ffffff;
}

.container h1 {
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #fff;
}

.container h2 {
  margin-top: 15px;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;

  color: #ffd500;
}

.container__content {
  margin-top: 40px;
}

.container p {
  margin-top: 15px !important;
  font-size: 15px;
  margin: 0;
  color: #ffffff;
  letter-spacing: 0.5px;
}
