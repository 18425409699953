.container-reset {
  text-align: center;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.h1-reset {
  color: #ffd500;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 10px;
}
.para-reset {
  color: #ffffff;
  font-size: 20px;
  margin: 0;
}
.para-reset-text {
  color: #ffffff;
  font-size: 20px;
  margin: 0;
}
.label-input {
  font-size: 13px;
  color: #666;
  line-height: 1.5;
  padding-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-self: start;
  margin-top: 20px;
}
.content-input {
  width: 100%;
  position: relative;
  padding-bottom: 13px;
}

.input-reset {
  display: block;
  width: 100%;
  background: 0 0;
  font-size: 18px;
  color: #333;
  line-height: 1.2;
  padding: 0 5px;
}

form input {
  width: 100%;
  height: 60px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 0 24px;
}
.card {
  width: 500px;
  background: #fff;
  padding: 42px 55px 45px;
  display: inline-block;
  margin: 0 auto;
  color: #ffffff;
  background-color: #141414;
  border: none;
}

.img-reset {
  width: 220px;
  margin: 10px;
}

.link-button {
  border: none;
  background-color: #ffd500;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: #c8d0d8;
  color: #ffffff;
  font-size: 14px;
  color: #333;
  font-weight: 500;
  padding: 15px 50px;
  text-decoration: none;
  transition: filter 0.2s;
  cursor: pointer;
}

.link-button:hover {
  filter: brightness(90%);
  text-decoration: none;
  color: #ffffff;
}

.link-reset {
  margin-top: 80px;
}

@media (max-width: 858px) {
  .card {
    width: 100%;
    padding: 0;
  }
}