.paymentProfile-main-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media (max-width: 858px) {
    .paymentProfile-main-container {
        padding: 0 !important;
        flex-direction: column;
    }

    .voltar-btn {
        display: unset;
        border-radius: 50px !important;
        font-family: 'Work-Sans' sans-serif;
        text-transform: capitalize;
        font-weight: 600;
        width: 100% !important;
    }
}