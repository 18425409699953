.Logo {
  width: 100px;
  /* height: 40px; */
  flex-direction: column;
  display: flex;
  margin-left: 6%;
}
.barsIcon {
  color: #ffffff;
  font-size: 30px;
  float: right;
  cursor: pointer;
  display: none;
}

.BsSearch {
  margin-right: 5%;
  border-radius: 20px;
}
.Login {
  background-color: "#FFF";
  color: "#000000";
}
.Register {
  background-color: "#fffb00";
  color: "#000000";
  width: "300px";
  border-radius: "20px";
  height: "38px";
  margin-right: "5%";
}

.menu-responsivo-deslogado,
.busca-responsive,
.menu-responsivo-usuario {
  display: none;
}

.menu-default-deslogado,
.menu-default-usuario {
  display: flex;
}

/* .checkbtn {

} */
#check {
  display: none;
}

@media (max-width: 858px) {
  .NavbarResponsive {
    display: none;
    padding: 20px;
    margin: 10px 0;
    transition: all 0.5s ease-in-out;
  }
  
  .navbar {
    position: fixed;
    z-index: 100;
    width: 100%;
  }

  .Login {
    margin-top: 10px;
  }
  .Register {
    margin-top: 10px;
  }
  .barsIcon {
    display: inline;
  }
  #check:checked ~ .NavbarResponsive {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-default-deslogado {
    display: none;
  }
  
  .default {
    display: none;
  }

  .MuiPopover-paper {
    width: calc(100% + 32px);
    height: calc(100vh - 72px);
    position: absolute;
    max-height: 100vh !important ;
    top: 72px !important;
    max-width: calc(100% + 32px) !important;
    margin-left: 32px;
    background-color: #343434 !important;
    z-index: 0;
  }

  .MuiList-padding {
    padding: 0 60px !important;
  }

  .MuiMenuItem-root {
    padding: 20px 0px !important;
    border-bottom: 2px solid #ffd500 !important;
    font-weight: 500 !important;
    color: white !important;
  }

  .busca-responsive {
    display: block;
    margin: 10px auto 34px auto;
    padding: 0 8px;
    width: 100%;
  }

  .busca-responsive input {
    margin: 0;
    width: 100%;
  }

  .MuiPopover-root {
    z-index: 99 !important;
  }
}

