.BtbLogin {
  margin-top: 20px;
  border-radius: 20px;
  background-color: #ffd500;
  color: #252525;
  width: 100%;
}
.BtbRegisterLogin {
  border-radius: 20px;
  margin-top: 10px;
  background-color: #252525;
}
.BtbRegister {
  border-radius: 20px;
  margin-top: 10px;
  width: 450px;
  background-color: #ffd500;
  color: #ffffff;
  width: 100%;
}


@media (max-width: 858px) {

  .modalDetails {
    margin-bottom: 24px;
  }

  .modalDetails .modal-body {
    background-color: #252525;
    padding: 0;
  } 
  
}