.confirmCvvBorder{
    background: rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmCvvContainer{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: white;
    height: 350px;
    width: 550px;

    padding: 20px;
    font-Family: 'Work Sans';
}

.confirmCvvContainer p{
    font-size: 22.5px;
    padding: 10px;
}

.cvvContainerInput{
    width: 200px;
    padding: 15px;
    margin-bottom: 30px;
}

.buttonContainer{
    height: 50px;
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonContainer button{
    width: 120px;
    height: 40px;
    border-radius: 20px;

    background: #FFD500;
    border: #FFD500;
}

@media (max-width: 858px) {
    .confirmCvvContainer {
        max-width: 90%;
    }

    .buttonContainer {
        max-width: 90%;
    }
}
