


@media (max-width: 858px){
    .ticket-grid {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .game-card {
        width: 100% !important;
        max-width: 100% !important;
    }

    .game-card+.game-card {
        margin-top: 24px;
    }
}
