@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}

html,
body,
#root {
  height: 100vh;
  background-color: #141414;
}

::-webkit-scrollbar-track {
  background-color: #212529;
}

::-webkit-scrollbar {
  width: 6px;
  background: #212529;
}

::-webkit-scrollbar-thumb {
  background: #dad7d7;
  border-radius: 25px;
}

code {
  font-family: "Work Sans", sans-serif;
}

.logo {
  width: 100px;
  height: 40px;
  flex-direction: column;
  display: flex;
  border-radius: 5%;
  margin-left: 6%;
}

.PopUp_border__Obuuz {
  z-index: 1000;
}

#root {
  width: 100vw;
  overflow: hidden
}

.MuiPickersCalendarHeader-switchHeader,
.MuiPickersDay-day  {
  color: black !important;
}

label[for="date-picker-inline"],
.MuiFormHelperText-root.Mui-error {
  display: none;
}

.date-picker {
  border: 1px solid #ced4da !important;
  height: 38px;
  border-radius: 20px;
  padding: 6px 12px !important;
  margin: 0 !important;
  margin-top: 5% !important;
}

.date-picker input {
  padding: 0 !important;
  height: 24px;
  color: #495057 !important;
  line-height: 24px;
}

.date-picker input::-webkit-input-placeholder {
  color: #495057;
  opacity: 0.8;
}

.date-picker input:-ms-input-placeholder {
  color: #495057;
  opacity: 0.8;
}

.date-picker input::placeholder {
  color: #495057;
  opacity: 0.8;
}

.date-picker button {
  padding: 0;
}

.MuiInput-underline:before,
.MuiInput-underline:after,
.MuiInput-underline.Mui-error:after {
  border-bottom: none !important;
}

.Logo {
  width: 100px;
  /* height: 40px; */
  flex-direction: column;
  display: flex;
  margin-left: 6%;
}
.barsIcon {
  color: #ffffff;
  font-size: 30px;
  float: right;
  cursor: pointer;
  display: none;
}

.BsSearch {
  margin-right: 5%;
  border-radius: 20px;
}
.Login {
  background-color: "#FFF";
  color: "#000000";
}
.Register {
  background-color: "#fffb00";
  color: "#000000";
  width: "300px";
  border-radius: "20px";
  height: "38px";
  margin-right: "5%";
}

.menu-responsivo-deslogado,
.busca-responsive,
.menu-responsivo-usuario {
  display: none;
}

.menu-default-deslogado,
.menu-default-usuario {
  display: flex;
}

/* .checkbtn {

} */
#check {
  display: none;
}

@media (max-width: 858px) {
  .NavbarResponsive {
    display: none;
    padding: 20px;
    margin: 10px 0;
    transition: all 0.5s ease-in-out;
  }
  
  .navbar {
    position: fixed;
    z-index: 100;
    width: 100%;
  }

  .Login {
    margin-top: 10px;
  }
  .Register {
    margin-top: 10px;
  }
  .barsIcon {
    display: inline;
  }
  #check:checked ~ .NavbarResponsive {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-default-deslogado {
    display: none;
  }
  
  .default {
    display: none;
  }

  .MuiPopover-paper {
    width: calc(100% + 32px);
    height: calc(100vh - 72px);
    position: absolute;
    max-height: 100vh !important ;
    top: 72px !important;
    max-width: calc(100% + 32px) !important;
    margin-left: 32px;
    background-color: #343434 !important;
    z-index: 0;
  }

  .MuiList-padding {
    padding: 0 60px !important;
  }

  .MuiMenuItem-root {
    padding: 20px 0px !important;
    border-bottom: 2px solid #ffd500 !important;
    font-weight: 500 !important;
    color: white !important;
  }

  .busca-responsive {
    display: block;
    margin: 10px auto 34px auto;
    padding: 0 8px;
    width: 100%;
  }

  .busca-responsive input {
    margin: 0;
    width: 100%;
  }

  .MuiPopover-root {
    z-index: 99 !important;
  }
}


.Input {
  align-self: center;
}
.Email {
  border-radius: 20px;
  margin-top: 5%;
  width: 300px;
}
.Password {
  border-radius: 20px;
}
.Register {
  border-radius: 20px;
}
.BottomCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ForgotPassword {
  font-size: 1rem;
  margin-top: 5px;
  display: flex;
  align-self: flex-end;
  color: #ffd500;
  font-family: "Work Sans", sans-serif;
  border: none;
  background-color: transparent;
}
.ForgotPassword:hover {
  text-decoration: underline;
}
.KeepConnected {
  display: flex;
  justify-content: space-between;
}
.BtbLogin {
  margin-top: 20px;
  border-radius: 20px;
  background-color: #ffd500;
  color: #252525;
  width: 100%;
  font-family: "Work Sans", sans-serif;
}
.BtbRegister {
  border-radius: 20px;
  margin-top: 10px;
  background-color: #252525;
  font-family: "Work Sans", sans-serif;
}
.errorMessage {
  color: red;
  font-size: 12px;
  padding: 6px 12px;
}

.BtbLogin {
  margin-top: 20px;
  border-radius: 20px;
  background-color: #ffd500;
  color: #252525;
  width: 100%;
}
.BtbRegisterLogin {
  border-radius: 20px;
  margin-top: 10px;
  background-color: #252525;
}
.BtbRegister {
  border-radius: 20px;
  margin-top: 10px;
  width: 450px;
  background-color: #ffd500;
  color: #ffffff;
  width: 100%;
}


@media (max-width: 858px) {

  .modalDetails {
    margin-bottom: 24px;
  }

  .modalDetails .modal-body {
    background-color: #252525;
    padding: 0;
  } 
  
}
.PopUp_border__Obuuz{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.PopUp_popUpModalContent__2HbCv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: .75rem;
    padding: 0 20px;
}

.PopUp_popUpBody__gcu1h {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.PopUp_popUpContainer__1IVeY{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding: 20px;

    border-radius: 30px;
    z-index: 90000;
}

.PopUp_popUpContainer__1IVeY > p {
    margin-bottom: 30px;
    font-family: 'Work Sans', 'Regular roman';
    font-size: 20px;
    text-align: center;
}

.PopUp_popUpContainer__1IVeY div img {
    width: 80px;
    margin-bottom: 20px;
}

.PopUp_popUpContainer__1IVeY div button{
    width: 90px;
    height: 35px;
    border-radius: 20px;

    background: #FFD500;
    border: #FFD500;
}

.PopUp_input__2P0Dz {
    background-color: #343434;
    border-radius: 50;
    color: #fff;
    text-transform: capitalize;
    font-family: 'Work Sans';
}

.PopUp_MuiInputBase-input__3gjM6 {
    color: red;
}

.PopUp_input__2P0Dz:disabled {
    color: white;
}

.PopUp_modal-open__16XVZ .PopUp_modal__3IFqL {
    z-index: 9999999;
}

/* import erro from '../assets/icons/Ativo8.png';
import styles from '../styles/PopUp.module.css';
import sucess from '../assets/icons/Ativo7.png'; */

@media (max-width: 858px) {

    .PopUp_popUpModalContent__2HbCv {
        width: 80%;
        padding: 20px 0;
    }
    .PopUp_popUpContainer__1IVeY {
        width: 100%;
    }

}
.paymentProfile-main-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media (max-width: 858px) {
    .paymentProfile-main-container {
        padding: 0 !important;
        flex-direction: column;
    }

    .voltar-btn {
        display: unset;
        border-radius: 50px !important;
        font-family: 'Work-Sans' sans-serif;
        text-transform: capitalize;
        font-weight: 600;
        width: 100% !important;
    }
}
.profile-container {
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 858px) {
    .right-container-profile {
        /* width: 100vw; */
        display: flex;
    }

    .historico-grid-xs6 {
        width: 100% !important;
        max-width: 100% !important;
    }

    .historico-grid-xs6+.historico-grid-xs6 {
        margin-top: 24px !important;
    }

    .divider {
        display: none;
    }

    .voltar-btn-container {
        width: 50%;
        margin: 0 auto;
        margin-top: 32px;
    }

    .profile-container {
        height: auto;
    }

    .more-detail-dialog .MuiDialog-paper {
        overflow-x: hidden !important;
        max-height: none;
        margin: 16px;
    }
}
.ConfirmCvv_confirmCvvBorder__AXKiB{
    background: rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.ConfirmCvv_confirmCvvContainer__18GJe{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: white;
    height: 350px;
    width: 550px;

    padding: 20px;
    font-Family: 'Work Sans';
}

.ConfirmCvv_confirmCvvContainer__18GJe p{
    font-size: 22.5px;
    padding: 10px;
}

.ConfirmCvv_cvvContainerInput__14mji{
    width: 200px;
    padding: 15px;
    margin-bottom: 30px;
}

.ConfirmCvv_buttonContainer__2a5hg{
    height: 50px;
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ConfirmCvv_buttonContainer__2a5hg button{
    width: 120px;
    height: 40px;
    border-radius: 20px;

    background: #FFD500;
    border: #FFD500;
}

@media (max-width: 858px) {
    .ConfirmCvv_confirmCvvContainer__18GJe {
        max-width: 90%;
    }

    .ConfirmCvv_buttonContainer__2a5hg {
        max-width: 90%;
    }
}


.ver-ingressos-btn{
    text-transform: capitalize;
    font-family: 'Work Sans', sans-serif;
    border-radius: 50%;
    width: 100%;
}

.finalizar-btns-container {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.appleStoreLink {
    margin-left: -15px;
}

.storeImgsContainer {
    height: 80px;
    margin-top: 10px;
}

@media (max-width: 858px) {
    .left-container-responsive {
        padding: 0;
    }

    .storeImgsContainer {
        flex-direction: column;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: 0;
    }

    .finalizar-btns-container {
        flex-direction: column;
        height: 80px;
        margin: 0;
    }

    .appleStoreLink {
        margin-left: 0;
    }
    
    .finalizar-btns-container div+ div {
        margin-top: 10px;
    }

    .ver-ingressos-btn {
        padding: 0 !important;
        padding-bottom: 32px !important;
    }
}



@media (max-width: 858px){
    .ticket-grid {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .game-card {
        width: 100% !important;
        max-width: 100% !important;
    }

    .game-card+.game-card {
        margin-top: 24px;
    }
}

.container-verified {
  text-align: center;
  padding: 40px 0;
  background-color: #141414;
}
.h1-verified {
  color: #ffd500;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 10px;
}
.para-verified {
  color: #ffffff;
  font-size: 20px;
  margin: 0;
}

.card {
  width: 500px;
  background: #fff;
  padding: 42px 55px 45px;
  display: inline-block;
  margin: 0 auto;
  color: #ffffff;
  background-color: #141414;
}

.img-verified {
  width: 100px;
  margin: 30px;
}

.link-button {
  height: 60px;
  background-color: #ffd500;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: #c8d0d8;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  padding: 20px;
  text-decoration: none;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  cursor: pointer;
}

.link-button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
  text-decoration: none;
  color: #ffffff;
}

.link-verified {
  margin-top: 80px;
}

@media (max-width: 858px) {
  .card {
    width: 100%;
    padding: 0;
  }
}

.container-reset {
  text-align: center;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.h1-reset {
  color: #ffd500;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 10px;
}
.para-reset {
  color: #ffffff;
  font-size: 20px;
  margin: 0;
}
.para-reset-text {
  color: #ffffff;
  font-size: 20px;
  margin: 0;
}
.label-input {
  font-size: 13px;
  color: #666;
  line-height: 1.5;
  padding-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-self: start;
  margin-top: 20px;
}
.content-input {
  width: 100%;
  position: relative;
  padding-bottom: 13px;
}

.input-reset {
  display: block;
  width: 100%;
  background: 0 0;
  font-size: 18px;
  color: #333;
  line-height: 1.2;
  padding: 0 5px;
}

form input {
  width: 100%;
  height: 60px;
  color: #333;
  border: 1px solid #dcdce6;
  border-radius: 8px;
  padding: 0 24px;
}
.card {
  width: 500px;
  background: #fff;
  padding: 42px 55px 45px;
  display: inline-block;
  margin: 0 auto;
  color: #ffffff;
  background-color: #141414;
  border: none;
}

.img-reset {
  width: 220px;
  margin: 10px;
}

.link-button {
  border: none;
  background-color: #ffd500;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: #c8d0d8;
  color: #ffffff;
  font-size: 14px;
  color: #333;
  font-weight: 500;
  padding: 15px 50px;
  text-decoration: none;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  cursor: pointer;
}

.link-button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
  text-decoration: none;
  color: #ffffff;
}

.link-reset {
  margin-top: 80px;
}

@media (max-width: 858px) {
  .card {
    width: 100%;
    padding: 0;
  }
}
.container {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;

  color: #fff;
}

.container__header {
  text-align: center;
}

.container__header p {
  margin-top: 20px !important;
  font-size: 15px !important;
  text-decoration: underline !important;
  margin: 0;
  color: #ffffff;
}

.container h1 {
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #fff;
}

.container h2 {
  margin-top: 15px;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;

  color: #ffd500;
}

.container__content {
  margin-top: 40px;
}

.container p {
  margin-top: 15px !important;
  font-size: 15px;
  margin: 0;
  color: #ffffff;
  letter-spacing: 0.5px;
}

.root {
    width: 100%;
    padding: 0 16px;
}

.root-responsive {
    display: none;
}

.boxes-container {
    display: flex;
}

.main-box {
    width: 70%;
}


@media (max-width: 858px) {
    .root {
        display: none;
    }

    .root-responsive {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
    }

    .responsive-grid {
        padding: 0 8px;
        width: 100%;
        max-width: 100% !important;
    }

    .responsive-grid .g-card-cont+.g-card-cont {
        margin-top: 24px;
    }
    
    .g-card-cont {
        padding: 0 !important;
        width: 100%;
        min-width: 100%;
    }

    .grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .boxes-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-box {
        width: 100%;
    }

    .main-box .MuiTypography-h5 {
        font-size: 1rem;
    }

    .MuiGrid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}
