.logo {
  width: 100px;
  height: 40px;
  flex-direction: column;
  display: flex;
  border-radius: 5%;
  margin-left: 6%;
}

.PopUp_border__Obuuz {
  z-index: 1000;
}

#root {
  width: 100vw;
  overflow: hidden
}

.MuiPickersCalendarHeader-switchHeader,
.MuiPickersDay-day  {
  color: black !important;
}

label[for="date-picker-inline"],
.MuiFormHelperText-root.Mui-error {
  display: none;
}

.date-picker {
  border: 1px solid #ced4da !important;
  height: 38px;
  border-radius: 20px;
  padding: 6px 12px !important;
  margin: 0 !important;
  margin-top: 5% !important;
}

.date-picker input {
  padding: 0 !important;
  height: 24px;
  color: #495057 !important;
  line-height: 24px;
}

.date-picker input::placeholder {
  color: #495057;
  opacity: 0.8;
}

.date-picker button {
  padding: 0;
}

.MuiInput-underline:before,
.MuiInput-underline:after,
.MuiInput-underline.Mui-error:after {
  border-bottom: none !important;
}
